<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    class="csn-rating-empty-icon"
  >
    <path
      fill-rule="evenodd"
      d="M12,16.39 L8.24,18.66 L9.23,14.38 L5.91,11.5 L10.29,11.13 L12,7.09 L13.71,11.13 L18.09,11.5 L14.77,14.38 L15.76,18.66 M22,10.24 L14.81,9.63 L12,3 L9.19,9.63 L2,10.24 L7.45,14.97 L5.82,22 L12,18.27 L18.18,22 L16.54,14.97 L22,10.24 Z"
    />
  </svg>
</template>
<script>
import { RATING_EMPTY_ICON } from '@/constants'

export default {
  name: RATING_EMPTY_ICON,
}
</script>
